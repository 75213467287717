import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ClientState from "./contexts/ClientContext";

const Home = React.lazy(() => import("./screens/Home"));
const PaymentConfirmation = React.lazy(
  () => import("./screens/PaymentConfirmation")
);
const Apply4Research = React.lazy(
  () => import("./screens/help/Apply4Research")
);

const AboutTechniques = React.lazy(() => import("./screens/AboutTechniques"));
const About = React.lazy(() => import("./screens/About"));const Partners = React.lazy(() => import("./screens/Partners"));
const AboutApp = React.lazy(() => import("./screens/AboutApp"));
const Contact = React.lazy(() => import("./screens/Contact"));
const Pricing = React.lazy(() => import("./screens/Pricing"));
const ResultsReport = React.lazy(
  () => import("./screens/results/ResultsReport.jsx")
);
const ResultsReportTrainees = React.lazy(
  () => import("./screens/results/ResultsReportTrainees.jsx")
);
const ResultsReportClients = React.lazy(
  () => import("./screens/results/ResultsReportClients.jsx")
);
const TestimonialsAdvisors = React.lazy( () => import("./screens/results/TestimonialsAdvisors.jsx") );


const Videos = React.lazy(() => import("./screens/Videos"));

const Privacy = React.lazy(() => import("./screens/Privacy"));
const TermsConditions = React.lazy(() => import("./screens/TermsConditions"));

// Shared
const SetupLanguage = React.lazy(() => import("./screens/SetupLanguage"));

const AssessIntro = React.lazy(() => import("./screens/assess/AssessIntro"));
const AssessPCL5 = React.lazy(() => import("./screens/assess/AssessPCL5"));
const AssessPHQ9 = React.lazy(() => import("./screens/assess/AssessPHQ9"));
const AssessGAD7 = React.lazy(() => import("./screens/assess/AssessGAD7"));
const AssessResults = React.lazy(
  () => import("./screens/assess/AssessResults")
);

//////////////////
const HelpMenu = React.lazy(() => import("./screens/help/HelpMenu"));
const HelpFaqs = React.lazy(() => import("./screens/help/HelpFaqs"));

const HelpHomePageIcon = React.lazy(
  () => import("./screens/help/HelpHomePageIcon")
);
const HelpSpeedTest = React.lazy(() => import("./screens/help/HelpSpeedTest"));
const HelpDiagnosis = React.lazy(() => import("./screens/help/HelpDiagnosis"));

////////////////////////
const App: React.FC = () => {
  return (
    <div>
      <ClientState>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/SetupLanguage" element={<SetupLanguage />} />
              <Route path="/About" element={<About />} />
              <Route path="/Partners" element={<Partners />} />
              <Route path="/AboutApp" element={<AboutApp />} />
              <Route path="/AboutTechniques" element={<AboutTechniques />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/HelpMenu" element={<HelpMenu />} />
              <Route path="/HelpFaqs" element={<HelpFaqs />} />
              <Route path="/HelpHomePageIcon" element={<HelpHomePageIcon />} />
              <Route path="/HelpSpeedTest" element={<HelpSpeedTest />} />
              <Route path="/HelpDiagnosis" element={<HelpDiagnosis />} />
              <Route path="/AssessIntro/:id" element={<AssessIntro />} />
              <Route path="/AssessPCL5" element={<AssessPCL5 />} />
              <Route path="/AssessPHQ9" element={<AssessPHQ9 />} />
              <Route path="/AssessGAD7" element={<AssessGAD7 />} />
              <Route path="/AssessResults" element={<AssessResults />} />
              <Route path="/Pricing" element={<Pricing />} />
              <Route path="/ResultsReport" element={<ResultsReport />} />
              <Route
                path="/ResultsReportTrainees"
                element={<ResultsReportTrainees />}
              />
              <Route
                path="/ResultsReportClients"
                element={<ResultsReportClients />}
              />              <Route
              path="/TestimonialsAdvisors"
              element={<TestimonialsAdvisors />}
            />
              <Route path="/Videos" element={<Videos />} />{" "}
              <Route
                path="/PaymentConfirmation"
                element={<PaymentConfirmation />}
              />
              <Route path="/Apply4Research" element={<Apply4Research />} />
              <Route path="/Privacy" element={<Privacy />} />
              <Route path="/TermsConditions" element={<TermsConditions />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </Router>
      </ClientState>
    </div>
  );
};

export default App;
