import React, { createContext, useEffect, useState, useContext } from "react";

interface IClientState {
  uuid: string;
  phone: string;
  pin: string;
  userName: string;
  langNum: number;
  langCode: string;
  email: string;
  whatsapp: string;
  age: string;
  gender?: string;
  country: string;
  done1Start: boolean;
  done2PreAssess: boolean;
  done3Essen: boolean;
  done4Interview: boolean;
  done5UNB1: boolean;
  done5UNB2: boolean;
  done5UNB3: boolean;
  done6TR1: boolean;
  done6TR2: boolean;
  done6TR3: boolean;
  done7Care: boolean;
  done8Abuse: boolean;
  done9PostAssess: boolean;
  preDate: string;
  postDate: string;
  IESPre: number;
  IESPost: number;
  PCL5Pre: number;
  PCL5Post: number;
  PHQ9Pre: number;
  PHQ9Post: number;
  GAD7Pre: number;
  GAD7Post: number;
  interview1: string;
  interview2: string;
  interview3: string;
  interview4: string;
  interview5: string;
  interview6: string;
  interview1Run: boolean;
  interview2Run: boolean;
  interview3Run: boolean;
  interview4Run: boolean;
  interview5Run: boolean;
  interview6Run: boolean;
  dateAppRegistered: Date;
  RCTGroup: string;
  freePassCode: string;
  avatarId: string;
}

interface IClientContext {
  clientState: IClientState;
  updateClientState: (state: Partial<IClientState>) => void;
  resetClientState: () => void;
  setInterviews: (index: number, value: string, run: boolean) => void;
}

const initialState: IClientState = {
  uuid: "",
  phone: "",
  pin: "0000",
  userName: "",
  langNum: 0,
  langCode: "en-GB",
  email: "",
  whatsapp: "",
  age: "",
  gender: "",
  country: "",
  done1Start: false,
  done2PreAssess: false,
  done3Essen: false,
  done4Interview: false,
  done5UNB1: false,
  done5UNB2: false,
  done5UNB3: false,
  done6TR1: false,
  done6TR2: false,
  done6TR3: false,
  done7Care: false,
  done8Abuse: false,
  done9PostAssess: false,
  preDate: "",
  postDate: "",
  IESPre: 0,
  IESPost: 0,
  PCL5Pre: 0,
  PCL5Post: 0,
  PHQ9Pre: 0,
  PHQ9Post: 0,
  GAD7Pre: 0,
  GAD7Post: 0,
  interview1: "",
  interview2: "",
  interview3: "",
  interview4: "",
  interview5: "",
  interview6: "",
  interview1Run: false,
  interview2Run: false,
  interview3Run: false,
  interview4Run: false,
  interview5Run: false,
  interview6Run: false,
  dateAppRegistered: new Date(),
  RCTGroup: "",
  freePassCode: "",
  avatarId: "",
};

export const ClientContext = createContext<IClientContext>({
  clientState: initialState,
  updateClientState: () => {},
  resetClientState: () => {},
  setInterviews: () => {},
});

const getKey = (uuid: string) => "client_" + uuid;

const ClientState = (props: { children: React.ReactNode }) => {
  const [clientState, setClientState] = useState<IClientState>(() => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    const storedState = localStorage.getItem(getKey(uuidOnSS || ""));
    return storedState ? JSON.parse(storedState) : initialState;
  });

  const updateClientState = (newState: Partial<IClientState>) => {
    const updatedState = { ...clientState, ...newState };
    setClientState(updatedState);
    saveClientStateToLocalAndBackend(updatedState);
  };

  const resetClientState = () => {
    setClientState(initialState);
    saveClientStateToLocalAndBackend(initialState);
  };

  const setInterviews = (index: number, value: string, run: boolean) => {
    const newState = {
      ...clientState,
      [`interview${index}`]: value,
      [`interview${index}Run`]: run,
    };
    setClientState(newState);
    saveClientStateToLocalAndBackend(newState);
  };

  const saveClientStateToLocalAndBackend = async (state: IClientState) => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    if (uuidOnSS) {
      const key = getKey(uuidOnSS);
      localStorage.setItem(key, JSON.stringify(state));
      console.log("Updated localStorage with current clientState");
    } else {
      console.error(
        "No user ID found in sessionStorage to update localStorage"
      );
    }

    /*     const retryQueue = JSON.parse(localStorage.getItem("retryQueue") || "[]");
    const requestData: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/save-client-state`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: state,
    }; */

    /*     try {
      await axios(requestData);
      console.log("Client state saved to backend successfully");
    } catch (error) {
      console.error("Error saving client state:", error);
      retryQueue.push(requestData);
      localStorage.setItem("retryQueue", JSON.stringify(retryQueue));
    } */
  };

/*   const retryFailedRequests = async () => {
    const retryQueue: AxiosRequestConfig[] = JSON.parse(
      localStorage.getItem("retryQueue") || "[]"
    );
    const newRetryQueue: AxiosRequestConfig[] = [];

    for (const request of retryQueue) {
      try {
        await axios(request);
        console.log("Retried and succeeded:", request);
      } catch (error: any) {
        console.error("Retry failed:", error);
        newRetryQueue.push(request);
      }
    }

    localStorage.setItem("retryQueue", JSON.stringify(newRetryQueue));
  }; */

  useEffect(() => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    if (uuidOnSS) {
      const key = getKey(uuidOnSS);
      localStorage.setItem(key, JSON.stringify(clientState));
      console.log("Updated localStorage with current clientState");
      saveClientStateToLocalAndBackend(clientState);
    } else {
      console.error(
        "No user ID found in sessionStorage to update localStorage"
      );
    }
  }, [clientState]);

/*   useEffect(() => {
    const interval = setInterval(retryFailedRequests, 5000); // Retry every 5 seconds
    return () => clearInterval(interval);
  }, []); */

  useEffect(() => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    if (!uuidOnSS) {
      const newUuid = generateUUID();
      sessionStorage.setItem("uuidOnSS", newUuid);
      const key = getKey(newUuid);
      localStorage.setItem(key, JSON.stringify(clientState));
    }
  }, []);

  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  return (
    <ClientContext.Provider
      value={{
        clientState,
        updateClientState,
        resetClientState,
        setInterviews,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientProvider");
  }
  return context;
};

export default ClientState;
